import React, { FC } from 'react';

import UniversalImage from 'components/common/UniversalImage';

import { IPageBreakerProps } from './model';
import './PageBreaker.scss';

const PageBreaker: FC<IPageBreakerProps> = ({ img, imgAlt }) =>
  img ? (
    <UniversalImage
      imageAlt={imgAlt}
      img={img}
      wrapperClassName="gs-page-breaker"
      objectFitData={{ objectFit: 'cover' }}
      backgroundColor
    />
  ) : null;
export default PageBreaker;
