import React, { FC, useEffect } from 'react';

import ControlledCarousel from 'components/ControlledCarousel';
import { gtmService } from 'services/gtmService';

import RecommendedProduct from './RecommendedProduct';
import responsiveObj from './constants';
import { IRecommendedProductsComponent } from './model';

import './RecommendedProducts.scss';

const RecommendedProducts: FC<IRecommendedProductsComponent> = ({
  exploreProductsTitle,
  exploreProductsList,
  pageName,
}): React.ReactElement => {
  useEffect(() => {
    const adjustedProductItems = exploreProductsList.map(({ properties }) => ({
      title: properties.exploreProductName,
    }));
    const timeoutId = gtmService.emitProductListingView(pageName, adjustedProductItems);

    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div className="recommended-products">
      <h3 className="recommended-products__title">{exploreProductsTitle}</h3>
      <ControlledCarousel
        responsiveObj={responsiveObj}
        carouselControls={{
          ariaLabelPrev: '<',
          ariaLabelNext: '>',
        }}
      >
        {exploreProductsList.map(
          ({
            properties: {
              exploreProductName,
              exploreProductDescription,
              exploreProductColor,
              exploreProductImage,
              exploreProductImageAlt,
              exploreProductSideImage,
              exploreProductSideImageAlt,
              exploreProductLink,
            },
          }) => (
            <RecommendedProduct
              name={exploreProductName}
              description={exploreProductDescription}
              color={exploreProductColor?.[0]?.properties?.colorPicker?.label}
              image={exploreProductImage}
              imageAlt={exploreProductImageAlt}
              sideImage={exploreProductSideImage}
              sideImageAlt={exploreProductSideImageAlt}
              link={exploreProductLink?.[0]?.url}
            />
          )
        )}
      </ControlledCarousel>
    </div>
  );
};

export default RecommendedProducts;
