import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import classNames from 'classnames';
import DangerouslySetInnerHtml from '../DangerouslySetInnerHtml';
import { QuoteProps } from './models';

import './Quote.scss';

const Quote: FC<QuoteProps> = ({ content, background }) => {
  const bgColor = background?.[0]?.properties.colorPicker?.label
    ? `${background[0].properties.colorPicker.label}-default-bg`
    : '';
  const quoteClasses = classNames('quote', {
    [bgColor]: background?.[0]?.properties?.colorPicker?.label,
  });

  return (
    <div className={quoteClasses}>
      <Container fluid>
        <DangerouslySetInnerHtml element="div" html={content} />
      </Container>
    </div>
  );
};

export default Quote;
