import React, { FC, useMemo, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classnames from 'classnames';

import ArticleTeaser from 'components/ArticleTeaser';
import ArticleBodySection from 'components/ArticleBodySection';
import PageBreaker from 'components/PageBreaker';
import BreadCrumbs from 'components/BreadCrumbs';
import Layout from 'components/Layout';
import ArticleExtraInfo from 'components/ArticleExtraInfo';
import TagLinksList from 'components/common/TagLinksList';
import RelatedProducts from 'components/RelatedProducts';
import UniversalImage from 'components/common/UniversalImage';
import ArticleSubNav from 'components/ArticleSubNav';
import FeaturedArticles from 'components/FeaturedArticles';
import ExtendedFeaturedArticles from 'components/ExtendedFeaturedArticles';

import { ISubNavItem } from 'components/ArticleSubNav/model';
import { parseNFButton } from 'components/common/NFButton/parsers';
import { parseExtendedFeaturedArticles } from 'components/ExtendedFeaturedArticles/parsers';

import { IArticlePageBody } from './model';
import './ArticlePage.scss';

interface IPropsArticlePage extends PageProps {
  pageContext: { breadcrumbs: UmbracoTypes.IBreadCrumb[]; relatedProducts: string[] };
  data: IArticlePageBody;
}

const ArticlePage: FC<IPropsArticlePage> = ({
  pageContext: { breadcrumbs, relatedProducts: relatedProductsLinks },
  data: {
    defaultFeaturedArticlesByTag,
    unbrandedFeaturedArticlesByTag,
    relatedProducts,
    unbrandedPage,
    defaultPage,
    carouselControls,
    header,
    footer,
    siteSettings,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    brandSettings,
    extendedFeaturedArticlesPainAdviceByLink,
    extendedFeaturedArticlesPainAdviceByTag,
    extendedFeaturedArticlesSymptomsByLink,
    extendedFeaturedArticlesSymptomsByTag,
    defaultPageLangs,
    unbrandedPageLangs,
    legalPopup,
    articleConfigs,
  },
}) => {
  const isUnbrandedType = Boolean(unbrandedPage.nodes?.[0]);
  const pageData = isUnbrandedType ? unbrandedPage.nodes[0] : defaultPage.nodes[0];
  const featuredArticlesByTag = isUnbrandedType
    ? unbrandedFeaturedArticlesByTag.nodes
    : defaultFeaturedArticlesByTag.nodes;
  const { nodes: langs } = isUnbrandedType ? unbrandedPageLangs : defaultPageLangs;

  const {
    relatedProductsConfig,
    relatedArticles,
    extraInfo,
    disclaimer,
    title,
    author,
    pageTheme,
    overview,
    publishingDate,
    authorTopInfo,
    tags,
    description,
    updateDate,
    createDate,
    image,
    lang,
    imgAlt,
    bulletColor,
    articleBody,
    seoMetaTitle,
    seoMetaDescription,
    seoMetaKeywords,
    seoExternalHreflangs,
    ogImage,
    subNavigation,
    extentedRelatedArticles,
    medicalInfo,
    pageName,
  } = pageData;
  const parsedExtentedRelatedArticles = extentedRelatedArticles?.[0]
    ? parseExtendedFeaturedArticles({
        extendedFeaturedArticles: extentedRelatedArticles?.[0]?.properties,
        articlesByLink: [
          ...extendedFeaturedArticlesPainAdviceByLink?.nodes,
          ...extendedFeaturedArticlesSymptomsByLink?.nodes,
        ],
        articlesByTag: [
          ...extendedFeaturedArticlesPainAdviceByTag?.nodes,
          ...extendedFeaturedArticlesSymptomsByTag?.nodes,
        ],
        carouselControls,
      })
    : undefined;

  const [subNavItems] = useState<ISubNavItem[]>(() => {
    const newNavItems: ISubNavItem[] = articleBody
      .filter(({ structure }) => structure === 'Article Anchor Block')
      .map(({ properties }) => ({
        anchorName: properties?.anchorName || '',
      }));

    return newNavItems;
  });

  const orderingComponents = useMemo(
    () => [
      extraInfo?.[0] ? (
        <ArticleExtraInfo
          key="ArticleExtraInfo"
          title={extraInfo[0].properties.title}
          body={extraInfo[0].properties.body}
          bgColor={extraInfo[0].properties.bgColor}
          orderedList={extraInfo[0].properties.orderedList}
        />
      ) : null,
      <ArticleBodySection
        bulletColor={bulletColor}
        content={articleBody}
        articleConfigs={articleConfigs}
        updateDate={updateDate}
        createDate={createDate}
        lang={lang}
      />,
    ],
    [extraInfo, bulletColor, articleBody]
  );

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        ogImage,
      }}
      header={header}
      footer={footer}
      siteSettings={siteSettings}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      legalPopup={legalPopup}
      langSettings={{
        currentLang: lang,
        langs,
      }}
    >
      <section
        className={classnames('article', {
          [`${pageTheme}`]: pageTheme,
        })}
      >
        <Container fluid>
          <Row>
            <Col lg={{ span: 8, offset: 2 }}>
              <BreadCrumbs data={breadcrumbs} />
            </Col>
          </Row>
        </Container>
        <ArticleTeaser
          data={{
            title,
            body: description,
            updateDate,
            medicalInfo,
            author,
            publishingDate,
            overview,
            lang,
            disclaimer,
            articleConfigs,
          }}
        >
          {authorTopInfo && authorTopInfo?.length > 0 ? (
            <Container fluid>
              <Row>
                <Col lg={{ span: 8, offset: 2 }}>
                  <section className="author-top-info">
                    {authorTopInfo?.[0]?.properties?.authorImage ? (
                      <UniversalImage img={authorTopInfo?.[0]?.properties?.authorImage} />
                    ) : null}
                    <p className="author-label">{authorTopInfo?.[0]?.properties?.authorLabel}</p>
                  </section>
                </Col>
              </Row>
            </Container>
          ) : null}
          {tags?.length ? (
            <Container fluid>
              <Row>
                <Col lg={{ span: 8, offset: 2 }}>
                  <TagLinksList
                    tagsFormat="smallLabel"
                    tagsList={tags.map(({ title: name, relatedPage: link }) => ({ name, link }))}
                  />
                </Col>
              </Row>
            </Container>
          ) : null}
        </ArticleTeaser>
        {image ? <PageBreaker img={image} imgAlt={imgAlt} /> : null}
        {subNavigation?.[0] ? (
          <ArticleSubNav
            sections={subNavItems}
            title={subNavigation[0].properties?.title}
            bulletColor={
              subNavigation[0].properties.bulletColor[0].properties.colorPicker?.label || 'red'
            }
            ariaLabel={subNavigation[0].properties.sectionLandmark}
          />
        ) : null}
        {extraInfo?.[0]?.properties.position?.[0] === 'above'
          ? orderingComponents
          : orderingComponents.reverse()}
        {relatedProducts.nodes?.length ? (
          <RelatedProducts
            title={relatedProductsConfig?.[0]?.properties?.relatedProductsTitle}
            showBtn={relatedProductsConfig?.[0]?.properties?.shopBtnsShow === '1'}
            btn={
              relatedProductsConfig?.[0]?.properties?.sectionCtaBtn?.length
                ? parseNFButton(
                    relatedProductsConfig?.[0]?.properties?.sectionCtaBtn?.[0]?.properties
                  )
                : undefined
            }
            products={relatedProducts.nodes}
            productsLinks={relatedProductsLinks}
            carouselControls={carouselControls}
            listingName={pageName}
          />
        ) : null}
        {relatedArticles?.[0]?.properties ? (
          <FeaturedArticles
            bgColor={relatedArticles[0].properties.featuredArticlesBgColor}
            title={relatedArticles[0].properties.featuredArticlesSectionTitle}
            subtitle={relatedArticles[0].properties.featuredArticlesSectionSubtitle || ''}
            btn={
              relatedArticles[0].properties.featuredArticlesCTAButtonData?.length
                ? {
                    ...parseNFButton(
                      relatedArticles[0].properties.featuredArticlesCTAButtonData[0].properties
                    ),
                  }
                : undefined
            }
            articles={relatedArticles[0].properties.featuredArticlesData}
            mode={relatedArticles[0].properties.featuredArticlesSelectedArticleMode}
            articlesByTag={featuredArticlesByTag}
            carouselControls={carouselControls}
          />
        ) : null}
        {parsedExtentedRelatedArticles ? (
          <ExtendedFeaturedArticles {...parsedExtentedRelatedArticles} />
        ) : null}
      </section>
    </Layout>
  );
};

export const query = graphql`
  fragment FragmentArticlePage on IUmbracoArticleBase {
    ...FragmentSeo
    id
    lang
    pageName
    pageTheme
    subNavigation {
      properties {
        title
        bulletColor {
          ...FragmentColorProps
        }
        sectionLandmark
      }
    }
    relatedArticles {
      properties {
        ...FragmentFeaturedArticles
      }
    }
    ...FragmentMedicalInfo
    link
    description
    overview
    disclaimer
    title
    author
    publishingDate
    authorTopInfo {
      properties {
        authorLabel
        authorImage {
          svg {
            content
          }
        }
      }
    }
    updateDate
    createDate
    extraInfo {
      ...FragmentArticleExtraInfo
    }
    bulletColor {
      ...FragmentColorProps
    }
    articleBody {
      properties {
        ...FragmentArticleBodySection
      }
      structure
    }
    image {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 2000, srcSetBreakpoints: [360, 400, 500, 600, 700, 1000, 1200, 2000]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      focalPoint {
        x
        y
      }
    }
    imgAlt
    tags {
      id
      name
      title
      relatedPage
    }
    relatedProductsConfig {
      properties {
        ...FragmentRelatedProductsConfig
      }
    }
    extentedRelatedArticles {
      properties {
        ...FragmentExtendedFeaturedArticles
      }
    }
    isArticleWithProducts
  }

  query(
    $lang: String!
    $articleTagId: Int
    $link: String!
    $relatedProducts: [String]
    $pageIdRegex: String
    $extendedFeaturedArticlesByTagIds: [Int]
    $extendedFeaturedArticlesByLinks: [String]
  ) {
    ...FragmentExtendedFeaturedArticlesData
    relatedProducts: allUmbracoProduct(
      filter: { link: { nin: [$link], in: $relatedProducts }, lang: { eq: $lang } }
      limit: 6
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
    defaultFeaturedArticlesByTag: allUmbracoArticles(
      filter: {
        tags: { elemMatch: { id: { eq: $articleTagId } } }
        link: { ne: $link }
        lang: { eq: $lang }
      }
    ) {
      nodes {
        ...FragmentFeaturedArticleData
      }
    }
    unbrandedFeaturedArticlesByTag: allUmbracoUnbrandedArticle(
      filter: {
        tags: { elemMatch: { id: { eq: $articleTagId } } }
        link: { ne: $link }
        lang: { eq: $lang }
      }
    ) {
      nodes {
        ...FragmentFeaturedArticleData
      }
    }
    defaultPage: allUmbracoArticles(filter: { link: { eq: $link } }) {
      nodes {
        ...FragmentArticlePage
      }
    }
    unbrandedPage: allUmbracoUnbrandedArticle(filter: { link: { eq: $link } }) {
      nodes {
        ...FragmentArticlePage
      }
    }
    carouselControls: siteSettings(lang: { eq: $lang }) {
      ariaLabelNext
      ariaLabelPrev
    }
    defaultPageLangs: allUmbracoArticles(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    unbrandedPageLangs: allUmbracoUnbrandedArticle(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    ...FragmentCommonCompositions
    articleConfigs(lang: { eq: $lang }) {
      ...FragmentArticleConfigs
    }
  }
`;

export default ArticlePage;
