import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IFacts } from './model';
import './Facts.scss';

const Facts: FC<IFacts> = ({ title, facts, sectionAnchor, colWidth = { md: 12 } }) => {
  return (
    <Container fluid className="facts" id={sectionAnchor}>
      <Row>
        <Col {...colWidth}>
          {title ? <h2 className="facts__title">{title}</h2> : null}
          <ul className="facts__list">
            {facts.map(
              ({
                properties: { title: factTitle, subtitle, metric, description, image, imageAlt },
              }) => (
                <li className="facts__list-item" key={metric}>
                  {image ? (
                    <div className="facts__item-image">
                      <UniversalImage img={image} imageAlt={imageAlt} />
                    </div>
                  ) : (
                    <>
                      <p className="facts__item-title">{factTitle}</p>
                      {subtitle ? <p className="facts__item-subtitle">{subtitle}</p> : null}
                      <p className="facts__item-metric">{metric}</p>
                      <DangerouslySetInnerHtml
                        html={description}
                        className="facts__item-description"
                      />
                    </>
                  )}
                </li>
              )
            )}
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export const query = graphql`
  fragment FragmentsFacts on IFacts {
    title
    sectionAnchor
    facts {
      properties {
        description
        metric
        subtitle
        title
        image {
          ...FragmentFluidSmallImage
        }
        imageAlt
      }
    }
  }
`;

export default Facts;
