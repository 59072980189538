import { IResponsiveObj } from 'components/ControlledCarousel/model';

const responsiveObj: IResponsiveObj = {
  largeDesktop: {
    breakpoint: { max: 6000, min: 1650 },
    items: 3,
    partialVisibilityGutter: 0,
  },
  desktop: {
    breakpoint: { max: 1650, min: 1300 },
    items: 2,
    partialVisibilityGutter: 150,
  },
  tablet: {
    breakpoint: { max: 1300, min: 768 },
    items: 1,
    partialVisibilityGutter: 200,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 100,
  },
};

export default responsiveObj;
