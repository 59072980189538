import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';

import NumberDotList from 'components/common/NumberDotList';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import './OrderedList.scss';

const OrderedList: FC<ArticleTypes.IOrderedList> = ({
  orderedList = [],
  title,
  limit,
  pointsColor,
}) => (
  <div className="ordered-list">
    <Container fluid>
      {title ? (
        <h2 className="ordered-list__title" dangerouslySetInnerHTML={{ __html: title }} />
      ) : null}
      {orderedList?.length ? (
        <ul className="ordered-list__list">
          {orderedList.map(({ properties: { text } }, index) => {
            const id = `${text}_${index}`;

            return !limit || index < limit ? (
              <li key={id}>
                <NumberDotList bgColor={pointsColor} number={index + 1} />
                <DangerouslySetInnerHtml className="ordered-list-item" html={text} />
              </li>
            ) : null;
          })}
        </ul>
      ) : null}
    </Container>
  </div>
);

export const query = graphql`
  fragment FragmentOrderedListI on IOrderedList {
    title
    limit
    orderedList {
      properties {
        text
      }
      structure
    }
    pointsColor {
      ...FragmentColorProps
    }
  }
  fragment FragmentOrderedListT on TOrderedList {
    properties {
      title
      limit
      orderedList {
        properties {
          text
        }
        structure
      }
      pointsColor {
        ...FragmentColorProps
      }
    }
  }
`;

export default OrderedList;
