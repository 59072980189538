import { parseNFButton } from '../NFButton/parsers';
import { IVideoComponentProps } from './model';

const parseVideo = (videoBlock: VideoBlock.IVideoProperties): IVideoComponentProps => ({
  video: videoBlock.videoLink,
  videoTitle: videoBlock?.videoTitle,
  videoDescription: videoBlock?.videoDescription,
  previewImg: {
    imgAlt: videoBlock?.previewImg[0].properties.imgAlt,
    featuredImage: videoBlock?.previewImg[0].properties.featuredImage,
  },
  btnPlay: videoBlock?.btnPlay?.svg?.content,
  btnPlayAriaLabel: videoBlock?.btnPlayAriaLabel,
  btn: videoBlock?.videoCTABtn?.length
    ? { ...parseNFButton(videoBlock.videoCTABtn[0].properties) }
    : undefined,
  isVideoRounded: videoBlock.isVideoRounded,
  videoBottomOverlappingTitle: videoBlock.videoBottomOverlappingTitle,
  videoOverlappingLabel: videoBlock?.videoOverlappingLabel?.length
    ? {
        coloredText: videoBlock?.videoOverlappingLabel?.[0]?.properties.coloredText,
        coloredTextColor: videoBlock.videoOverlappingLabel?.[0]?.properties?.coloredTextColor?.[0]
          ?.properties?.colorPicker?.label as AppColours.TPaletteColour,
      }
    : undefined,
});

export default parseVideo;
