import React, { FC, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import ArticleMedicalInfo from 'components/ArticleMedicalInfo';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { articleFormatDate, getLocaleDate } from 'utils/helpers';
import { ArticleTeaserProps } from './model';

import './ArticleTeaser.scss';

const ArticleTeaser: FC<ArticleTeaserProps> = ({
  data: {
    title,
    disclaimer,
    body,
    updateDate,
    medicalInfo,
    author,
    publishingDate,
    overview,
    lang = '',
    articleConfigs,
  },
  children,
}) => {
  const articleDate = updateDate ? useMemo(() => articleFormatDate(updateDate, lang), []) : '';
  const publishingArticleDate = publishingDate
    ? useMemo(() => getLocaleDate(publishingDate, lang), [])
    : '';

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <div className="article-teaser">
              {disclaimer ? (
                <DangerouslySetInnerHtml className="article-teaser__disclaimer" html={disclaimer} />
              ) : null}
              <h1 className="article-teaser__title">{title}</h1>
              {articleConfigs?.showTeaserDate && articleDate ? (
                <span className="article-teaser__date">{`Last updated ${articleDate}`}</span>
              ) : null}
              {author ? (
                <p className="article-teaser__author">
                  {author}
                  {'. '}
                  {publishingArticleDate}
                </p>
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
      {children}
      {medicalInfo?.[0]?.properties ? (
        <ArticleMedicalInfo
          title={medicalInfo[0].properties.title}
          body={medicalInfo[0].properties.bodyText}
        />
      ) : null}
      <Container fluid>
        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <div className="article-teaser">
              {overview ? <p className="article-teaser__overview">{overview}</p> : null}
              {body ? (
                <div className="article-teaser__body" dangerouslySetInnerHTML={{ __html: body }} />
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ArticleTeaser;
