import React, { FC, ReactElement, useCallback } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { formatAnchor } from 'utils/helpers';
import { scrollToTarget } from 'utils/browser';
import ArticleSubNavTitle from './ArticleSubNavTitle';
import { IPropsArticleSubNav } from './model';
import './ArticleSubNav.scss';

const ArticleSubNav: FC<IPropsArticleSubNav> = ({
  sections,
  bulletColor,
  title,
  description,
  ariaLabel,
  colWidth = { md: 8 },
}): ReactElement | null => {
  const scrollCallback = useCallback((anchor) => () => scrollToTarget(anchor), []);

  return sections?.length > 0 ? (
    <Container fluid className="nf-article-subnav-container">
      <Row className="justify-content-md-center">
        <Col {...colWidth}>
          <nav className={`nf-article-subnav ${bulletColor}-default-bg`} aria-label={ariaLabel}>
            {title ? <ArticleSubNavTitle title={title} /> : null}
            {description ? (
              <DangerouslySetInnerHtml
                className="nf-article-subnav__description"
                html={description}
              />
            ) : null}
            <ul>
              {sections.map((section) =>
                section.anchorName ? (
                  <li key={formatAnchor(section.anchorName)}>
                    <button
                      type="button"
                      onClick={scrollCallback(`${formatAnchor(section.anchorName)}`)}
                    >
                      {section.anchorName}
                    </button>
                  </li>
                ) : null
              )}
            </ul>
          </nav>
        </Col>
      </Row>
    </Container>
  ) : null;
};

export default ArticleSubNav;
