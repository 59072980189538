import React from 'react';
import DangerouslySetInnerHtml from '../DangerouslySetInnerHtml';

import './Table.scss';

const Table = ({ content }: { content: string }) => {
  return <DangerouslySetInnerHtml element="div" html={content} className="table-container" />;
};

export default Table;
