import React, { FC } from 'react';
import classname from 'classnames';

import { NumberDotListProps } from './model';
import './NumberDotList.scss';

const NumberDotList: FC<NumberDotListProps> = ({
  number,
  bgColor = [{ properties: { colorPicker: { label: 'navy' } } }],
  numColor = [{ properties: { colorPicker: { label: 'grey' } } }],
}) => {
  const classes = classname('number-dot-list', {
    [`${bgColor?.[0].properties.colorPicker?.label}-default-bg`]: bgColor,
    [`${numColor?.[0].properties.colorPicker?.label}-default-text`]: bgColor,
  });

  return <span className={classes}>{number}</span>;
};
export default NumberDotList;
