import React, { FC } from 'react';

import { IArticleAnchorBlockProps } from './model';

const ArticleAnchorBlock: FC<IArticleAnchorBlockProps> = ({ content, id }) => (
  <div className="article-anchor-block" id={id}>
    <div dangerouslySetInnerHTML={{ __html: content }} />
  </div>
);
export default ArticleAnchorBlock;
