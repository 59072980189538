import { graphql } from 'gatsby';
import React, { FC } from 'react';
import classnames from 'classnames';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IReadReports } from './model';

import './ReadReportSection.scss';

const ReadReportSection: FC<IReadReports> = ({
  title,
  description,
  sectionAnchor,
  image,
  imageAlt,
  anchorText,
  fileLink,
  icon,
  sectionBackground,
  sectionTheme,
}) => (
  <section
    className={classnames('read-report', {
      [`${sectionBackground?.[0]?.properties?.colorPicker?.label}-default-bg`]: sectionBackground?.[0]
        ?.properties?.colorPicker?.label,
      [`${sectionTheme}`]: sectionTheme,
    })}
  >
    <Container fluid id={sectionAnchor}>
      <Row className="read-report__row">
        <Col className="read-report__text">
          <h2 className="read-report__title">{title}</h2>
          <DangerouslySetInnerHtml className="read-report__description" html={description} />
          {fileLink?.gatsbyDocFile?.relativePath && anchorText ? (
            <a
              href={fileLink?.gatsbyDocFile?.relativePath}
              className="read-report__download-link read-report__download-link--hide-xs nf-btn"
              download
            >
              {anchorText}
              {icon ? (
                <DangerouslySetInnerHtml
                  className="read-report__download-icon"
                  html={icon?.svg?.content}
                />
              ) : null}
            </a>
          ) : null}
        </Col>

        <Col className="read-report__image">
          <UniversalImage img={image} imageAlt={imageAlt} />
        </Col>
        {fileLink?.gatsbyDocFile?.relativePath && anchorText ? (
          <a
            href={fileLink?.gatsbyDocFile?.relativePath}
            className="read-report__download-link read-report__download-link--hide-md nf-btn"
            download
          >
            {anchorText}
            {icon ? (
              <DangerouslySetInnerHtml
                className="read-report__download-icon"
                html={icon?.svg?.content}
              />
            ) : null}
          </a>
        ) : null}
      </Row>
    </Container>
  </section>
);

export const query = graphql`
  fragment FragmentReadReport on IReadReport {
    sectionAnchor
    title
    description
    image {
      ...FragmentFluidSmallImage
    }
    imageAlt
    anchorText
    fileLink {
      gatsbyDocFile {
        relativePath
      }
    }
    icon {
      svg {
        content
      }
    }
    sectionBackground {
      properties {
        colorPicker {
          label
        }
      }
    }
  }
`;

export default ReadReportSection;
