import React, { FC } from 'react';
import classname from 'classnames';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import OrderedList from 'components/OrderedList';

import { IArticleExtraInfoComponentProps } from './model';
import './ArticleExtraInfo.scss';

const ArticleExtraInfo: FC<IArticleExtraInfoComponentProps> = ({
  title,
  body,
  orderedList,
  bgColor,
}) => {
  const classes = classname('article-extra-info', {
    [`${bgColor?.[0].properties.colorPicker?.label}-default-bg`]: bgColor,
  });

  return (
    <div className={classes} data-testid="article-extra-info">
      <Container fluid>
        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <h2 className="article-extra-info__title">{title}</h2>
            <DangerouslySetInnerHtml className="article-extra-info__body" html={body} />
            {orderedList?.[0] ? <OrderedList {...orderedList[0].properties} /> : null}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentArticleExtraInfo on TArticleExtraInfo {
    properties {
      title
      orderedList {
        ...FragmentOrderedListT
      }
      body
      bgColor {
        ...FragmentColorProps
      }
      position
    }
  }
`;

export default ArticleExtraInfo;
