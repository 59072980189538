import React, { FC } from 'react';
import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import { IDownloadFile } from './model';
import './DownloadFile.scss';

const DownloadFile: FC<IDownloadFile> = ({ title, anchorText, fileLink, image }) => {
  return (
    <div className="download-file">
      <DangerouslySetInnerHtml html={title} className="download-file__title" />
      <div className="download-file__content">
        <DangerouslySetInnerHtml html={image?.svg?.content} className="download-file__icon" />
        <a href={fileLink?.gatsbyDocFile?.relativePath} className="download-file__link" download>
          {anchorText}
        </a>
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentDownloadFile on IDownloadFile {
    fileLink {
      gatsbyDocFile {
        relativePath
      }
    }
    image {
      svg {
        content
      }
    }
    title
    anchorText
  }
`;
export default DownloadFile;
