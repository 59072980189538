import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IRecommendedProductComponent } from './model';

import './RecommendedProducts.scss';

const RecommendedProduct: FC<IRecommendedProductComponent> = ({
  name,
  description,
  color,
  image,
  imageAlt,
  sideImage,
  sideImageAlt,
  link,
}): React.ReactElement => {
  return (
    <Link key={name} to={link}>
      <article
        className={classNames(`recommended-product ${color}-default-bg`, {
          'recommended-product--with-side-image': sideImage,
        })}
      >
        <div className="recommended-product__body">
          <h3 className="recommended-product__name">{name}</h3>

          {image ? (
            <div className="recommended-product__image-wrapper">
              <UniversalImage
                img={image}
                wrapperClassName="recommended-product__image"
                imageAlt={imageAlt || ''}
              />
            </div>
          ) : null}

          <DangerouslySetInnerHtml
            html={description}
            className="recommended-product__description"
          />
        </div>

        <div>
          {sideImage ? (
            <div className="recommended-product__side-image-wrapper">
              <UniversalImage
                img={sideImage}
                wrapperClassName="recommended-product__side-image"
                imageAlt={sideImageAlt || ''}
              />
            </div>
          ) : null}
        </div>
      </article>
    </Link>
  );
};
export default RecommendedProduct;
